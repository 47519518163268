var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  Drupal.behaviors.comparisonItemV1 = {
    attached: false,
    nodes: {},

    selectors: {
      product: '.js-product-ui',
      productImageContainer: '.js-product-image',
      productImageDefault: '.js-product-brief__image-img',
      productImageAlt: '.js-product-brief__image-img--alt',
      productBadge: '.js-product-brief__badge',
      productBadgeContainer: '.js-product-brief__top',
      productLinkSpp: '.js-product__link-to-spp',
      productVtoLink: '.js-cta-vto-link',
      productVto: '.js-product-vto-link',
      productShade: '.js-sku-shade-name'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.setup($(context));
    },

    setup: function ($that) {
      var self = this;

      self.getDom($that);
      self.setEvents($that);
      self.loadSkus($that);
    },

    loadSkus: function ($that) {
      var self = this;
      var initialSku = '';
      var initialSkuBaseId = '';

      $that.nodes.$products.each(function () {
        var $product = $(this);
        // if the product is shaded the skuSelect triggers from brief shade picker files, no reason to duplicate
        // there are cases where shades file loads before brief, in which case we still need to load the sku
        var processShade =
          ($product.data('isShaded') && $product.data('shadeSelected')) ||
          !$product.data('isShaded');
        initialSkuBaseId = !!$product.data('customSku')
          ? $product.data('customSku')
          : $product.data('skuBaseId');
        initialSku = 'SKU' + initialSkuBaseId;

        if (!!$product.data('skuBaseId') && processShade) {
          $product.trigger('product.skuSelect', initialSkuBaseId);
        } else {
          self.toggleVtoLinks($product, $product.data('skuBaseId'));
          // skuSelect triggers before grids are fully loaded, need to update the ShadeRoutes on load
          self.updateShadeRoutes($product, initialSkuBaseId);
        }
      });
    },

    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$productImages = $(self.selectors.productImageDefault, $that);
      $that.nodes.$productAltImages = $(self.selectors.productImageAlt, $that);
      $that.nodes.$products = $(self.selectors.product, $that);
    },

    setEvents: function ($that) {
      var self = this;

      $(document).on('product.skuSelect', $that.nodes.$products.selector, function (e, skuBaseId) {
        var $product = $(this);
        self.toggleVtoLinks($product, skuBaseId);
        self.updateSkuImage($product, $that, skuBaseId);
        // uncoment if they want to update the badge on sku change ( using products untill then )
        // self.updateSkuBadge($product, skuBaseId);
        self.updateShadeRoutes($product, skuBaseId);
        self.updateShadeName($product, skuBaseId);
      });
    },

    updateShadeName: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      if (skuData) {
        var shadeName = skuData.SHADENAME;
        var shadeNameHex = skuData.HEX_VALUE_STRING;
        var $shadeTitle = $product.find(self.selectors.productShade);
        if ($product.find('[title="' + shadeName + '"]').hasClass('light-shade')) {
          $shadeTitle.addClass('light-shade');
        } else {
          $shadeTitle.removeClass('light-shade');
        }
        $shadeTitle.html(shadeName).css({ color: shadeNameHex });
      }
    },

    updateSkuImage: function ($product, $that, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      var $image = $product.find($that.nodes.$productImages);
      var $altImage = $product.find($that.nodes.$productAltImages);
      var $toggleImage = $product.find($that.nodes.$productModelImages);
      var skuImageLarge = null;
      var altImageLarge = null;
      var skuImageMedium = null;
      var altImageMedium = null;
      var skuImage = null;
      var altImage = null;
      var useSrc = null;
      var newSrc = null;

      if (!!skuData) {
        skuImageLarge = Boolean(skuData.LARGE_IMAGE) && skuData.LARGE_IMAGE[0]
          ? skuData.LARGE_IMAGE[0]
          : null;
        altImageLarge = Boolean(skuData.LARGE_IMAGE) && skuData.LARGE_IMAGE[1]
          ? skuData.LARGE_IMAGE[1]
          : null;
        skuImageMedium = Boolean(skuData.MEDIUM_IMAGE) && skuData.MEDIUM_IMAGE[0]
          ? skuData.MEDIUM_IMAGE[0]
          : null;
        altImageMedium = Boolean(skuData.MEDIUM_IMAGE) && skuData.MEDIUM_IMAGE[1]
          ? skuData.MEDIUM_IMAGE[1]
          : null;
        skuImage = skuImageLarge || skuImageMedium;
        altImage = altImageLarge || altImageMedium;

        if (Drupal.settings.globals_variables.images_cr21) {
          skuImageLarge = Boolean(skuData.IMAGE_LARGE) && skuData.IMAGE_LARGE[0]
            ? skuData.IMAGE_LARGE[0]
            : null;
          altImageLarge = Boolean(skuData.IMAGE_LARGE) && skuData.IMAGE_LARGE[1]
            ? skuData.IMAGE_LARGE[1]
            : null;
          skuImageMedium = Boolean(skuData.IMAGE_MEDIUM) && skuData.IMAGE_MEDIUM[0]
            ? skuData.IMAGE_MEDIUM[0]
            : null;
          altImageMedium = Boolean(skuData.IMAGE_MEDIUM) && skuData.IMAGE_MEDIUM[1]
            ? skuData.IMAGE_MEDIUM[1]
            : null;
          skuImage = skuImageLarge || skuImageMedium;
          altImage = altImageLarge || altImageMedium;
        }
        if (
          !!$product.data('customImage') &&
          parseInt(skuBaseId) === parseInt($product.data('customSku'))
        ) {
          skuImage = $product.data('customImage');
        }
        if (!!skuImage) {
          // update main image
          self.setImageSrc($image, skuImage);
        }
        if (!!altImage) {
          // update alt image
          self.setImageSrc($altImage, altImage);
        }
        if ($toggleImage.is(':visible')) {
          // have to update the Model image if it's visible and user changes the shade
          useSrc = $toggleImage.attr('src') || $toggleImage.data('src');
          newSrc = useSrc.replace($toggleImage.data('productCode'), skuData.PRODUCT_CODE);

          self.setImageSrc($toggleImage, newSrc);
        }
        $toggleImage
          .data('productCode', skuData.PRODUCT_CODE)
          .attr('data-product-code', skuData.PRODUCT_CODE);
      }
    },

    updateSkuBadge: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);

      if (!!skuData.MISC_FLAG_TEXT) {
        var $badge = $product.find(self.selectors.productBadge);
        var $badgeContainer = $product.find(self.selectors.productBadgeContainer);

        if ($badge.length < 1) {
          $badge = $('<div class="product-brief__badge js-product-brief__badge"></div>');
          $badgeContainer.append($badge);
        }
        $badge.html(skuData.MISC_FLAG_TEXT);
      }
    },

    toggleVtoLinks: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);
      var $productVtoCtaWrapper = $product.find(self.selectors.productVto);

      $productVtoCtaWrapper.addClass('hidden');
      if (skuData && (skuData.VTO_FLAG_ONLINE || skuData.VTO_FOUNDATION_ONLINE)) {
        $productVtoCtaWrapper.removeClass('hidden');
      }
    },

    updateShadeRoutes: function ($product, skuBaseId) {
      var self = this;
      var skuData = prodcat.data.getSku(skuBaseId);

      if (skuData && !!skuData.SHADENAME) {
        var $linksToSppVto = $product.find(self.selectors.productVtoLink);
        var $linksToSpp = $product.find(self.selectors.productLinkSpp).not($linksToSppVto);
        var shadeNameString = skuData.SHADENAME + '';
        var processedShadename = shadeNameString.replace(/\s/g, '_');

        processedShadename = encodeURIComponent(processedShadename).replace(
          /[!'()*]/g,
          function (c) {
            return '%' + c.charCodeAt(0).toString(16);
          }
        );
        self.updateShadeRouteLinks($linksToSppVto, processedShadename);
        self.updateShadeRouteLinks($linksToSpp, processedShadename);
      }
    },

    updateShadeRouteLinks: function ($links, processedShadename) {
      if ($links.length < 1) {
        return;
      }
      var linkPieces = $links.attr('href').split(/[?#]+/);
      var link = linkPieces[0];
      var linkParams = linkPieces.length > 2 ? '?' + linkPieces[2] : '';
      var shadeRoute = link + '?shade=' + processedShadename + linkParams;

      $links.attr('href', shadeRoute);
    },

    setImageSrc: function ($image, imageSrc) {
      if (imageSrc && $image.attr('src') !== imageSrc) {
        $image.attr('src', imageSrc);
      }
    }
  };
})(jQuery);
